var INTERNAL_TAGGED_LESION = "internal::taggedlesion";
var REFRESH_VIEWPORTS = "internal::refreshViewports";
var TOGGLE_ANNOTATIONS = "internal::toggleAnnotations";
var CUSTOM_EVENTS = {
  INTERNAL_TAGGED_LESION: INTERNAL_TAGGED_LESION,
  REFRESH_VIEWPORTS: REFRESH_VIEWPORTS,
  TOGGLE_ANNOTATIONS: TOGGLE_ANNOTATIONS
};
var TOOL_NAMES = {
  CUSTOM_LENGTH: "CustomLength",
  FREEHAND_ROI: "FreehandRoi",
  RECTANGLE_ROI: "RectangleRoi",
  TEXT: "Text",
  LENGTH: "Length",
  SEG_FREEHAND: "FreehandScissors",
  SEG_SCISSORS: "CorrectionScissors",
  SEG_BRUSH: "Brush"
};
export default {
  CUSTOM_EVENTS: CUSTOM_EVENTS,
  TOOL_NAMES: TOOL_NAMES
};